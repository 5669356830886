import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";

import { accountService, alertService, billService } from "../../_services";
import Currency from "react-currency-formatter";
import { filter } from "rxjs/operators";
import { ResetPassword } from "../../account/ResetPassword";

import { Members } from "./Members";
import { Roles } from "./Roles";

function View({ history, match }) {

  const { id } = match.params;
  const [user, setUser] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);
  const [userModalOpen, setUserModalOpen] = useState(false);
  const [modalTitle, setModalTitle] = useState("");
  const [modalComponent, setModalComponent] = useState(null);
  const [bill, setBill] = useState({
    purchaseItems: [
      {
        price_data: {
          currency: "INR",
          unit_amount: 0,
          product_data: {
            name: "",
            description: "",
          },
        },
        quantity: 1,
      },
    ],
  });

  const [isSubmitting, setSubmitting] = useState(false);

  /// Edited By rabindra
  const [billData, setBillData] = useState();
  const [editBill, setEditBill] = useState(false);
  const [createBill, setCreateBill] = useState(false);
  const [createBillData, setCreateBillData] = useState({
    payStatus: "paid",
    purchaseItems: [
      {
        price_data: {
          currency: "INR",
          unit_amount: 0,
          product_data: {
            name: "",
            description: "",
          },
        },
        quantity: 1,
      },
    ],
  });
  const [checkBox, setCheckBox] = useState("payed");
  const [role, setRole] = useState({});
  const [editUserDetails, setEditUserDetails] = useState();

  useEffect(() => {
    // get user and set form fields
    accountService.getById(id).then((user) => {
      // console.log(user);
      billService.getUserBill(user.id).then((bills) => {
        if (bills && bills.length) {
          bills[0].purchaseItems = JSON.parse(bills[0].purchaseItems);
          setBill(bills[0]);
        }
      });
      setUser(user);

      billService.getUserBills(id).then((bills) => {
        if (bills && bills.length) {
          setBillData(bills);
          // console.log(bills);
        }
      });

      // console.log("user");
      // console.log(billData);
    });

    const subscription = accountService.user.subscribe((x) => setRole(x));
  }, [isSubmitting]);

  const updateBill = (e, property) => {
    let newBill = bill;
    newBill[property] = e.target.value;
    setBill(newBill);
  };

  const updateBillProduct = (e, field) => {
    let newBill = bill;
    newBill["purchaseItems"][0]["price_data"]["product_data"][field] =
      e.target.value;
    setBill(newBill);
  };

  const createBillProduct = (e, field) => {
    let newBill = createBillData;
    newBill["purchaseItems"][0]["price_data"]["product_data"][field] =
      e.target.value;
    setCreateBillData(newBill);
  };

  const updateBillPrice = (e, field) => {
    let newBill = bill;
    newBill["purchaseItems"][0]["price_data"][field] = e.target.value;
    setBill(newBill);
  };

  const createBillPrice = (e, field) => {
    let newBill = createBillData;
    newBill["purchaseItems"][0]["price_data"][field] = e.target.value;
    setCreateBillData(newBill);
  };

  const updateBillPayStatus = (e, field) => {
    let newBill = bill;
    newBill[field] = e.target.value;
    setBill(newBill);
  };

  const createBillPayStatus = (e, field) => {
    let newBill = createBillData;
    // console.log(e.target.value);
    newBill[field] = e.target.value;
    setCreateBillData(newBill);
  };

  function onBillingInfoUpdate(id) {
    setSubmitting(true);
    let billSummary = {};
    billSummary.purchaseItems = [
      {
        price_data: {
          currency: bill.purchaseItems[0].price_data.currency,
          unit_amount: bill.purchaseItems[0].price_data.unit_amount.toString(),
          product_data: {
            name: bill.purchaseItems[0].price_data.product_data.name,
            description:
              bill.purchaseItems[0].price_data.product_data.description,
          },
        },
        quantity: 1,
      },
    ];
    billSummary.customer_email = user.email;
    billSummary.success_url = "https://cms.digitallive24.com";
    billSummary.cancel_url = "https://cms.digitallive24.com";
    billSummary.userId = user.id;
    billSummary.appName = "Signage-CMS";
    billSummary.stripeCustomer = "";
    billSummary.payType = "onetime";
    billSummary.payStatus = bill.payStatus; //TODO: make a state to mark payed on unpaid
    billSummary.invoicePath = "";
    billSummary.receiptPath = "";


    billService
      .updateBill(id, billSummary)
      .then((data) => {
        // console.log(data);
        alertService.success("Bill Updated Successfully", {
          keepAfterRouteChange: true,
        });
        setSubmitting(false); // submit animation stoped
        setEditBill(false); // Edit form is closing
        // history.push('.');
      })
      .catch((error) => {
        setSubmitting(false);
        alertService.error(error);
      });
  }

  function onCreateBill() {
    setSubmitting(true);
    let billSummary = {};
    billSummary.purchaseItems = [
      {
        price_data: {
          currency: createBillData.purchaseItems[0].price_data.currency,
          unit_amount:
            createBillData.purchaseItems[0].price_data.unit_amount.toString(),
          product_data: {
            name: createBillData.purchaseItems[0].price_data.product_data.name,
            description:
              createBillData.purchaseItems[0].price_data.product_data
                .description,
          },
        },
        quantity: 1,
      },
    ];
    billSummary.customer_email = user.email;
    billSummary.success_url = "https://cms.digitallive24.com";
    billSummary.cancel_url = "https://cms.digitallive24.com";
    billSummary.userId = user.id;
    billSummary.appName = "Signage-CMS";
    billSummary.stripeCustomer = "";
    billSummary.payType = "onetime";
    billSummary.payStatus = createBillData.payStatus;
    billSummary.invoicePath = "";
    billSummary.receiptPath = "";

    // console.log(billSummary);
    billService
      .createBill(billSummary)
      .then((data) => {
        // console.log(data);
        alertService.success("Bill Created Successfully", {
          keepAfterRouteChange: true,
        });
        setSubmitting(false); // submit animation stoped
        setCreateBill(false); // Edit form is closing
        // history.push('.');
      })
      .catch((error) => {
        setSubmitting(false);
        setCreateBill(false);
        alertService.error(error);
      });
  }

  function convertDateFormate(dateString) {
    const date = new Date(dateString);
    const month = date.getMonth() + 1;
    const dateNumber = date.getDate();
    const year = date.getFullYear();
    const formattedDate = `${month}/${dateNumber}/${year}`;
    return formattedDate;
  }

  const editBillData = async (fid) => {
    const data = await billService.getUserBills(id).then((bills) => {
      if (bills && bills.length) {
        return bills.filter((field) => {
          return field._id === fid;
        });
      }
    });
    data[0].purchaseItems = JSON.parse(data[0].purchaseItems);
    setBill(data[0]);
    // console.log(data[0]);
    setEditBill(true);
  };

  const openPayment = () => {
    return true;
  }

  const updateSetCheckBox = async (bid, status) => {
    setSubmitting(true);

    const data = await billService.getUserBills(id).then((bills) => {
      if (bills && bills.length) {
        return bills.filter((field) => {
          return field._id === bid;
        });
      }
    });

    data[0].purchaseItems = JSON.parse(data[0].purchaseItems);
    setBill(data[0]);

   

    let billSummary = {};
    billSummary.purchaseItems = [
      {
        price_data: {
          currency: data[0].purchaseItems[0].price_data.currency,
          unit_amount:
            data[0].purchaseItems[0].price_data.unit_amount.toString(),
          product_data: {
            name: data[0].purchaseItems[0].price_data.product_data.name,
            description:
              data[0].purchaseItems[0].price_data.product_data.description,
          },
        },
        quantity: 1,
      },
    ];
    billSummary.customer_email = user.email;
    billSummary.success_url = "https://cms.digitallive24.com";
    billSummary.cancel_url = "https://cms.digitallive24.com";
    billSummary.userId = user.id;
    billSummary.appName = "Signage-CMS";
    billSummary.stripeCustomer = "";
    billSummary.payType = "onetime";
    billSummary.payStatus = status;
    billSummary.invoicePath = "";
    billSummary.receiptPath = "";


    billService
      .updateBill(bid, billSummary)
      .then((data) => {
        // console.log(data);
        alertService.success("Bill Updated Successfully", {
          keepAfterRouteChange: true,
        });
        setSubmitting(false); // submit animation stoped
        setEditBill(false); // Edit form is closing
        // history.push('.');
      })
      .catch((error) => {
        setSubmitting(false);
        alertService.error(error);
      });
  };

  const UserDetails = ({ title, value }) => {
    const [field, setField] = useState(value);
    const [editfield, setEditField] = useState(false);
    const handleUpdate = async (e) => {
      e.preventDefault();
      const newUser = user;
      newUser[title] = field;
      await accountService.update(user.id, newUser);
      setEditField(false);
    };

    return (
      <li className="d-flex a col-md-6 col-12 align-items-center">
        <label className="text-capitalize mr-2">{title} : </label>
        {!editfield && <span>{" " + field}</span>}
        {editfield && (
          <form
            action=""
            className="d-flex align-content-center"
            onSubmit={handleUpdate}
          >
            {title === "currency" ? (
              <select
                id="curr-select"
                className="form-control mr-2 mb-2"
                defaultValue={field}
                onChange={(e) => setField(e.target.value)}
              >
                <option value="CAD">CAD</option>
                <option value="EUR">EUR</option>
                <option value="INR">INR</option>
                <option value="USD">USD</option>
                <option value="VND">VND</option>
              </select>
            ) : title === "role" ? (
              <select
                id="curr-select"
                className="form-control mr-2 mb-2"
                onChange={(e) => e.target.value}
              >
                <option value="SUPER-ADMIN">SUPER-ADMIN</option>
                <option value="SUPER-ADMIN">ADMIN</option>
              </select>
            ) : (
              <>
                <input
                  type="text"
                  name=""
                  id=""
                  value={field}
                  className="mr-2 mb-2"
                  onChange={(e) => setField(e.target.value)}
                />
              </>
            )}
            <button className="btn btn-rounded cursor-pointer">
              <i className="bi bi-check-circle text-success h3"></i>
            </button>
          </form>
        )}{" "}
        <span
          className=" p-2 text-capitalize  rounded-circle mybtn"
          onClick={() => setEditField(!editfield)}
        >
          {editfield ? (
           <button className="btn btn-rounded cursor-pointer"> <i class="bi bi-x-circle text-danger h3"></i></button>
          ) : (
           <button className="btn btn-rounded cursor-pointer"> <i class="bi bi-pen h6"></i></button>
          )}
        </span>
      </li>
    );
  };

  return (
    <div className={"user-details"} style={{ padding: "20px" }}>
      {role?.user?.role === "SUPER-ADMIN" && (
        <Link to={".."} className="btn btn-link">
          {"< Back"}
        </Link>
      )}
      <div className="d-md-flex justify-content-between ">
        <h1>{"Name: " + (user ? user.firstName + " " + user.lastName : "")}</h1>

        
      </div>
      {user && (
        <div className="user-detail-section">
          <div>
          <h4 style={{ padding: "10px 0px",display:'inline-block' }}>Personal Details</h4>
          <button
          style={{float:'right'}}
          onClick={() => {
            setModalOpen(true);
            setModalTitle("Reset Password");
            setModalComponent(
              <ResetPassword closeModal={() => setModalOpen(false)} />
            );
          }}
          className="text-primary border-only-btn"
        >
          Reset Password
        </button>
        </div>
          <div className="row">
          <UserDetails title="firstName" value={user.firstName} />
          <UserDetails title="lastName" value={user.lastName} />
            <UserDetails title="email" value={user.email} />
            <UserDetails title="phone" value={user.phone} />
            <UserDetails title="address" value={user.address} />
            {/* <UserDetails title="pincode" value={user.pincode} /> */}
            <UserDetails title="currency" value={user?.currency} />
            <UserDetails title="taxPerc" value={user?.taxPerc} />
            <UserDetails title="role" value={user.role} />
            {role?.user?.role === "SUPER-ADMIN" && (
          <UserDetails title="#Screens Allowed" value={user.screensAllowed} />
          )}
          </div>
        </div>
      )}

      {user && (
        <div className="user-detail-section">
        
          <div >
            <Roles modalState={modalOpen} showModal={(status) => setModalOpen(status)} showModalContent={(c) => {setModalOpen(true),setModalTitle('Add New Role'),setModalComponent(c)}} />
        </div>
        </div>
      )}

      {user && (
        <div className="user-detail-section">
         
          <div>
            <Members modalState={modalOpen} showModal={(status) => setModalOpen(status)} showModalContent={(c,title) => {setModalOpen(true),setModalTitle(title),setModalComponent(c)}}  />
          </div>
        </div>
      )}


      <div className="user-detail-section">
        
        <div >
        <h4 style={{display:"inline-block"}}>Bill Details</h4>
        {role?.user?.role === "SUPER-ADMIN" && (
          <button
            onClick={() => setCreateBill(true)}
            style={{float:'right'}}
            className="text-primary  border-only-btn"
          >
            Generate New Bill
          </button>
        )}
        </div>
         {user && (
        <div  id="bill_All_Details" style={{ marginTop: "20px" }}>
          <table className="table table-striped">
            <thead>
              <tr>
                <th style={{ width: "30%" }}>Due Date</th>
                <th style={{ width: "30%" }}>Status</th>
                <th style={{ width: "20%" }}>Amount</th>
                {role?.user?.role === "SUPER-ADMIN" && (
                  <th style={{ width: "10%" }}>Mark Paid</th>
                )}
                <th style={{ width: "10%" }}></th>
              </tr>
            </thead>
            <tbody>
              {billData &&
                billData.map((user) => (
                  <tr key={user._id}>
                    <td>{convertDateFormate(user.createdAt)}</td>
                    <td
                      style={{ textTransform: "uppercase" }}
                      className={`mt-2 badge badge-pill align-items-center ${
                        user.payStatus === "paid"
                          ? "badge-success"
                          : "badge-warning"
                      } statusButton`}
                    >
                      {user.payStatus}
                    </td>
                    <td>
                      <Currency
                        quantity={
                          user?.purchaseItems
                            ? JSON.parse(user?.purchaseItems)[0]?.price_data
                                ?.unit_amount / 100
                            : 0
                        }
                        currency={
                          user?.purchaseItems
                            ? JSON.parse(user.purchaseItems)[0].price_data
                                .currency
                            : "INR"
                        }
                      />
                    </td>
                    {role?.user?.role === "SUPER-ADMIN" && (
                      <td>
                        <input
                          type="checkbox"
                          className={'checkbox-common'}
                          name=""
                          id=""
                          checked={user.payStatus === "paid"}
                          onChange={() =>
                            updateSetCheckBox(
                              user._id,
                              user.payStatus === "paid" ? "unpaid" : "paid"
                            )
                          }
                        />
                      </td>
                    )}
                   {role?.user?.role === "SUPER-ADMIN" ? <td style={{ whiteSpace: "nowrap" }}>
                      <button
                        onClick={() => {
                          editBillData(user._id);
                        }}
                        className="btn btn-sm btn-primary mr-1"
                      >
                        Edit
                      </button>
                    </td>:
                    <td style={{ whiteSpace: "nowrap" }}>
                      <button
                        onClick={() => {
                          openPayment(user._id);
                        }}
                        className="btn btn-sm btn-primary mr-1"
                      >
                        Pay Now
                      </button>
                    </td>}
                  </tr>
                ))}
              {!user && (
                <tr>
                  <td colSpan="4" className="text-center">
                    <span className="spinner-border spinner-border-lg align-center"></span>
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      )}
      </div>

      {/* Edit Bill Start */}

      {editBill && user && (
        <div
          id="bill_summary"
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            width: "100%",
            height: "100vh",
            padding: "10px 20px",
          }}
        >
          <div
            style={{
              position: "absolute",
              top: 0,
              left: 0,
              width: "100%",
              height: "100vh",
            }}
            className="Form_blur_background"
            onClick={() => setEditBill(false)}
          ></div>
          <div
            style={{
              width: "500px",
              margin: "auto",
              background: "white",
              padding: "20px",
              borderRadius: "10px",
              zIndex: 10,
              position: "relative",
            }}
            className="box_shadow"
          >
            <h4>Billing Details</h4>
            {/* Title */}
            <div>
              <label>Title For Product/Service</label>
              <input
                id="title"
                onChange={(e) => updateBillProduct(e, "name")}
                type={"text"}
                defaultValue={
                  bill ? bill.purchaseItems[0].price_data.product_data.name : ""
                }
              />
            </div>
            {/* Description  */}
            <div>
              <label>Description For Product/Service</label>
              <textarea
                id="billText"
                defaultValue={
                  bill
                    ? bill.purchaseItems[0].price_data.product_data.description
                    : ""
                }
                onChange={(e) => updateBillProduct(e, "description")}
                placeholder={"Enter User Package details here..."}
                style={{ padding: "10px", width: "100%", height: "200px" }}
              ></textarea>
            </div>
            {/* Currency  */}
            <div>
              <label>Select Currency</label>
              <select
                id="curr-select"
                onChange={(e) => updateBillPrice(e, "currency")}
              >
                <option
                  selected={
                    bill && bill.purchaseItems[0].price_data.currency == "CAD"
                  }
                  value="CAD"
                >
                  CAD
                </option>
                <option
                  selected={
                    bill && bill.purchaseItems[0].price_data.currency == "EUR"
                  }
                  value="EUR"
                >
                  EUR
                </option>
                <option
                  selected={
                    bill && bill.purchaseItems[0].price_data.currency == "INR"
                  }
                  value="INR"
                >
                  INR
                </option>
                <option
                  selected={
                    bill && bill.purchaseItems[0].price_data.currency == "USD"
                  }
                  value="USD"
                >
                  USD
                </option>
                <option
                  selected={
                    bill && bill.purchaseItems[0].price_data.currency == "VND"
                  }
                  value="VND"
                >
                  VND
                </option>
              </select>

              <label>Pay Status</label>
              <select
                id="curr-select"
                onChange={(e) => updateBillPayStatus(e, "payStatus")}
              >
                <option
                  selected={bill && bill.payStatus == "paid"} // convert to lowercase to safe approach
                  value="paid"
                >
                  Paid
                </option>
                <option
                  selected={bill && bill.payStatus == "unpaid"}
                  value="unpaid"
                >
                  Unpaid
                </option>
              </select>

              <label>Amount to pay ( In cent )</label>
              <input
                id="amount"
                type="number"
                defaultValue={
                  bill
                    ? parseInt(bill.purchaseItems[0].price_data.unit_amount)
                    : 0
                }
                onChange={(e) => updateBillPrice(e, "unit_amount")}
              />
            </div>
            {/* Save  */}
            <button
              onClick={() => onBillingInfoUpdate(bill._id)}
              disabled={isSubmitting}
              className="btn btn-primary"
              style={{ margin: "20px" }}
            >
              {isSubmitting && (
                <span className="spinner-border spinner-border-sm mr-1"></span>
              )}
              Save
            </button>
            <button
              onClick={() => setEditBill(false)}
              className="btn btn-danger"
              style={{ margin: "20px" }}
            >
              close
            </button>
          </div>
        </div>
      )}

      {/* Edit Bill End */}

      {/* Create Bill Start */}
      {createBill && user && (
        <div
          id="bill_summary"
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            width: "100%",
            height: "100vh",
            padding: "10px 20px",
          }}
        >
          <div
            style={{
              position: "absolute",
              top: 0,
              left: 0,
              width: "100%",
              height: "100vh",
            }}
            className="Form_blur_background"
            onClick={() => setCreateBill(false)}
          ></div>
          <div
            style={{
              width: "500px",
              margin: "auto",
              background: "white",
              padding: "20px",
              borderRadius: "10px",
              zIndex: 10,
              position: "relative",
            }}
            className="box_shadow"
          >
            <h4>Billing Details</h4>
            {/* Title */}
            <div>
              <label>Title For Product/Service</label>
              <input
                id="title"
                onChange={(e) => createBillProduct(e, "name")}
                type={"text"}
                defaultValue={
                  createBillData
                    ? createBillData.purchaseItems[0].price_data.product_data
                        .name
                    : ""
                }
              />
            </div>
            {/* Description  */}
            <div>
              <label>Description For Product/Service</label>
              <textarea
                id="billText"
                defaultValue={
                  createBillData
                    ? createBillData.purchaseItems[0].price_data.product_data
                        .description
                    : ""
                }
                onChange={(e) => createBillProduct(e, "description")}
                placeholder={"Enter User Package details here..."}
                style={{ padding: "10px", width: "100%", height: "200px" }}
              ></textarea>
            </div>
            {/* Currency  */}
            <div>
              <label>Select Currency</label>
              <select
                id="curr-select"
                onChange={(e) => createBillPrice(e, "currency")}
              >
                <option
                  selected={
                    createBillData &&
                    createBillData.purchaseItems[0].price_data.currency == "CAD"
                  }
                  value="CAD"
                >
                  CAD
                </option>
                <option
                  selected={
                    createBillData &&
                    createBillData.purchaseItems[0].price_data.currency == "EUR"
                  }
                  value="EUR"
                >
                  EUR
                </option>
                <option
                  selected={
                    createBillData &&
                    createBillData.purchaseItems[0].price_data.currency == "INR"
                  }
                  value="INR"
                >
                  INR
                </option>
                <option
                  selected={
                    createBillData &&
                    createBillData.purchaseItems[0].price_data.currency == "USD"
                  }
                  value="USD"
                >
                  USD
                </option>
                <option
                  selected={
                    createBillData &&
                    createBillData.purchaseItems[0].price_data.currency == "VND"
                  }
                  value="VND"
                >
                  VND
                </option>
              </select>

              <label>Pay Status</label>
              <select
                id="curr-select"
                onChange={(e) => createBillPayStatus(e, "payStatus")}
              >
                <option
                  selected={
                    createBillData && createBillData.payStatus == "paid"
                  } // convert to lowercase to safe approach
                  value="paid"
                >
                  Paid
                </option>
                <option
                  selected={
                    createBillData && createBillData.payStatus == "unpaid"
                  }
                  value="unpaid"
                >
                  Unpaid
                </option>
              </select>

              <label>Amount to pay ( In cent )</label>
              <input
                id="amount"
                type="number"
                defaultValue={
                  createBillData
                    ? parseInt(
                        createBillData.purchaseItems[0].price_data.unit_amount
                      )
                    : 0
                }
                onChange={(e) => createBillPrice(e, "unit_amount")}
              />
            </div>
            {/* Save  */}
            <button
              onClick={() => onCreateBill()}
              disabled={isSubmitting}
              className="btn btn-primary"
              style={{ margin: "20px" }}
            >
              {isSubmitting && (
                <span className="spinner-border spinner-border-sm mr-1"></span>
              )}
              Create
            </button>
            <button
              onClick={() => setCreateBill(false)}
              className="btn btn-danger"
              style={{ margin: "20px" }}
            >
              close
            </button>
          </div>
        </div>
      )}

      {/* Create Bill End */}


     
      <div
        className="modal-popup"
        style={{ display: modalOpen ? "block" : "none" }}
      >
        <div className="modal">
          <article className="modal-container">
            <header className="modal-container-header">
              <h3 className="modal-container-title">{modalTitle}</h3>
              <button className="icon-button" onClick={()=>setModalOpen(false)}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  width="24"
                  height="24"
                >
                  <path fill="none" d="M0 0h24v24H0z" />
                  <path
                    fill="currentColor"
                    d="M12 10.586l4.95-4.95 1.414 1.414-4.95 4.95 4.95 4.95-1.414 1.414-4.95-4.95-4.95 4.95-1.414-1.414 4.95-4.95-4.95-4.95L7.05 5.636z"
                  />
                </svg>
              </button>
            </header>
            <section className="modal-container-body rtf">{modalComponent}</section>
            <footer style={{ display: "none" }} class="modal-container-footer">
              <button class="button is-ghost">Decline</button>
              <button class="button is-primary">Accept</button>
            </footer>
          </article>
        </div>
      </div>
    </div>
  );
}

export { View };
